import React, { FC, PropsWithChildren } from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import { IconDownload } from '@tabler/icons-react';

import { ActionIcon, Box, Card, Center, Group, SimpleGrid, Stack, Text } from '@mantine/core';
import { DatesRangeValue } from '@mantine/dates/lib/types/DatePickerValue';

import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

import { useAction } from '@core/router/action';
import { Sensor } from '@modules/iot/sensors/model';
import RangeFilter from '@modules/iot/sensors/sensoterra/components/RangeFilter';
import { SensorAction } from '@modules/iot/sensors/action';
import { MeasuresUtils } from '@shared/modules/measures/utils';
import HalfPieChart from '@shared/modules/charts/half-pie/HalfPieChart';
import { mapMeasureScaleToData } from '@shared/modules/charts/utils';
import { formatDate } from '@shared/modules/dates';
import HumidityLineChart from '@shared/modules/charts/line/HumidityLineChart';
import { Measures } from '@shared/modules/measures/model';
import { Utils } from '@shared/utils/model';

import getLastHumidityMeasure = MeasuresUtils.getLastHumidityMeasure;
import Percent = Utils.Percent;
import { renderOptional } from '@shared/utils/render';

interface HumidityMeasureProps {
  humidity: O.Option<Measures.Humidity>;
  dateRange: [Date, Date];
  setDateRange(range: HumidityMeasureProps['dateRange']): void;
  exportParams: Sensor.ExportSensorParams;
}

const HumidityMeasure: FC<PropsWithChildren<HumidityMeasureProps>> = ({
  humidity,
  children,
  setDateRange,
  dateRange: [startDate, endDate],
  exportParams,
}) => {
  const [exportLoading, exportTask] = useAction(SensorAction.exportMeasures);
  const handleExport = () => exportTask(exportParams);

  const lineChartData = pipe(
    humidity,
    O.map(({ values }) => values.map(({ value, at }) => ({ date: parseISO(at), soilHumidity: value }))),
  );

  const dateFormatter = (date: Date) =>
    formatDate(date, differenceInDays(endDate, startDate) < 1 ? 'HH:mm' : 'dd/MM/yyyy');

  const onDateRangeChange = ([startDate, endDate]: DatesRangeValue) => {
    if (startDate && endDate) setDateRange([startDate, endDate]);
  };

  const referenceArea = pipe(
    humidity,
    O.chain(({ scale }) =>
      pipe(
        scale.levels,
        A.findIndex(({ level }) => level === Measures.Humidity.Scale.Ideal),
        O.map(index =>
          pipe(
            scale.levels,
            A.lookup(index - 1),
            O.fold(
              () => ({ min: Percent.parse(0), max: scale.levels[index].until }),
              ({ until }) => ({ min: until, max: scale.levels[index].until }),
            ),
          ),
        ),
        O.alt(() =>
          pipe(
            scale,
            O.fromPredicate(({ last }) => last === Measures.Humidity.Scale.Ideal),
            O.chain(() => A.last(scale.levels)),
            O.map(({ until }) => ({ min: until, max: Percent.parse(1) })),
          ),
        ),
      ),
    ),
  );

  const measure = pipe(humidity, O.chain(getLastHumidityMeasure));

  const pieChartData = pipe(
    humidity,
    O.fold(
      () => [],
      ({ scale }) =>
        mapMeasureScaleToData(scale, {
          labels: Measures.Humidity.scaleLabel,
          colors: Measures.Humidity.scaleColor,
          bg: Measures.Humidity.scaleBg,
        }),
    ),
  );

  return (
    <Card
      p={22}
      radius={10}
      shadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      sx={theme => ({
        '&[data-with-border]': { border: `1px solid ${theme.colors.tertiary[2]}` },
      })}
    >
      <Stack>
        <Group position="apart">
          <Text size={18} weight={700} color="dark.5">
            Humidité du sol
          </Text>
          <ActionIcon
            variant="light"
            size={36}
            color="tertiary.2"
            c="tertiary.8"
            loading={exportLoading}
            onClick={handleExport}
          >
            <IconDownload strokeWidth={1} />
          </ActionIcon>
        </Group>
        <Group position="center">
          <RangeFilter dateRange={[startDate, endDate]} onDateRangeChange={onDateRangeChange} />
        </Group>
        <HumidityLineChart data={lineChartData} dateFormatter={dateFormatter} referenceArea={referenceArea} />

        <SimpleGrid
          pt={30}
          cols={2}
          sx={theme => ({ gridTemplateColumns: '1fr 2fr', borderTop: `1px solid ${theme.colors.gray[3]}` })}
        >
          <Stack spacing={10}>
            <Text weight={700} size={18}>
              État hydrique du sol
            </Text>
            <Text size={14}>Type de sol</Text>
            {children}
          </Stack>
          {renderOptional(measure, ({ value }) => (
            <>
              <HalfPieChart data={pieChartData} value={value} mah={192} />
              <span />
              <Center component="ul" p={0} m={0} style={{ gap: 10, flexWrap: 'wrap' }}>
                {pieChartData.map(({ name, color }) => (
                  <Box
                    key={name}
                    component="li"
                    display="inline-flex"
                    sx={theme => ({
                      alignItems: 'center',
                      listStyle: 'none',
                      ':not(:first-child)': {
                        marginLeft: 10,
                      },
                      ':before': {
                        content: '""',
                        display: 'inline-block',
                        width: 22,
                        height: 22,
                        background: theme.fn.themeColor(color),
                        borderRadius: 4,
                        marginRight: 8,
                      },
                    })}
                  >
                    <Text color={color} fz={12} fw={600}>
                      {name}
                    </Text>
                  </Box>
                ))}
              </Center>
            </>
          ))}
        </SimpleGrid>
      </Stack>
    </Card>
  );
};

export default HumidityMeasure;
