import React, { FC, useMemo, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

import * as Styled from './HalfPieChart.styles';
import { Charts } from '@shared/modules/charts/model';
import { Box, Text } from '@mantine/core';
import { useTheme } from '@emotion/react';
import { NumberUtils } from '@shared/utils/number';

const RADIAN = Math.PI / 180;
const VALUE_HEIGHT = 35;
const ACTIVE_SHAPE_DISTANCE = 10;

const CONTAINER_HEIGHT_OFFSET = VALUE_HEIGHT / 2 + ACTIVE_SHAPE_DISTANCE / 2;

// Le type des props devrait être PieLabelRenderProps mais très mal typé
interface PieLabelProps extends Record<string, any> {
  activeIndex: number;
}

const PieLabel = ({ activeIndex, index, cx, cy, midAngle, innerRadius, outerRadius, name, color }: PieLabelProps) => {
  const theme = useTheme();
  const { x, y } = useMemo(() => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;

    return {
      x: cx + radius * Math.cos(-midAngle * RADIAN),
      y: cy + radius * Math.sin(-midAngle * RADIAN),
    };
  }, [cx, cy, innerRadius, midAngle, outerRadius]);

  return (
    <text
      display={index === activeIndex ? 'block' : 'none'}
      x={x}
      y={y}
      fill={theme.fn.themeColor(color)}
      textAnchor="middle"
      dominantBaseline="central"
      fontSize={12}
      fontWeight={600}
    >
      {name}
    </text>
  );
};

const ActiveShape = ({ outerRadius, color, ...props }: any) => {
  const theme = useTheme();
  return (
    <g>
      <Sector {...props} outerRadius={outerRadius} />
      <Sector
        {...props}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + ACTIVE_SHAPE_DISTANCE}
        fill={theme.fn.themeColor(color)}
      />
    </g>
  );
};
interface HalfPieChartProps {
  data: Array<Charts.Pie.Data>;
  value: number;
  mah: number;
}

const HalfPieChart: FC<HalfPieChartProps> = ({ data, value, mah }) => {
  const [mounted, setMounted] = useState<boolean>(false);

  const handleMounted = () => setMounted(true);

  const theme = useTheme();

  const { activeIndex } = data.reduce(
    ({ activeIndex, sum }, val, index) => ({ sum: sum + val.value, activeIndex: value > sum ? index : activeIndex }),
    { sum: 0, activeIndex: 0 },
  );

  return (
    <Box mah={mah + CONTAINER_HEIGHT_OFFSET} pb={CONTAINER_HEIGHT_OFFSET} style={{ overflow: 'hidden' }}>
      <Box pos="relative" h="200%">
        <ResponsiveContainer>
          <PieChart margin={{ top: ACTIVE_SHAPE_DISTANCE }}>
            <Pie
              data={data}
              cy="50%"
              cx="50%"
              innerRadius="50%"
              outerRadius="100%"
              startAngle={180}
              endAngle={0}
              labelLine={false}
              label={<PieLabel activeIndex={activeIndex} />}
              dataKey="value"
              onAnimationEnd={handleMounted}
              animationDuration={0}
              activeShape={<ActiveShape />}
              activeIndex={activeIndex}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={theme.fn.themeColor(entry.bg)} strokeWidth={0} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

        {mounted ? (
          <>
            <Styled.HalfPieChartPointer
              style={{
                transform: `translateX(-50%) translateY(${ACTIVE_SHAPE_DISTANCE / 2}px) rotate(${value * 180 - 90}deg)`,
              }}
            />
            <Text
              component="span"
              display="flex"
              size={18}
              weight={600}
              pos="absolute"
              bottom="50%"
              left="50%"
              px={12}
              bg="dark.4"
              h={VALUE_HEIGHT}
              color="white"
              sx={{
                borderRadius: 20,
                transform: `translateX(-50%) translateY(calc(50% + ${ACTIVE_SHAPE_DISTANCE / 2}px))`,
                alignItems: 'center',
              }}
            >
              {NumberUtils.formatPercent(value)}
            </Text>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default HalfPieChart;
